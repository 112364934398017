"use client"

import React, {Fragment} from 'react'
import Link from 'next/link'
import {Popover, Transition} from '@headlessui/react'
import clsx from 'clsx'

import {Button} from '@/components/button'
import {Container} from '@/components/container'
import {Logo} from '@/components/logo'
import {NavLink} from '@/components/nav-link'
import {ArrowRightEndOnRectangleIcon} from "@heroicons/react/16/solid";

function MobileNavLink({href, onClick, children}: Readonly<{ href?: string, onClick?: () => void, children: React.ReactNode }>) {
  return (
    <Popover.Button as={Link} href={href ?? ""} onClick={onClick} className="block w-full p-2">
      {children}
    </Popover.Button>
  )
}

function logout() {
  fetch('/api/logout/', {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).then(() => {
    window.location.href = '/'
  })
}

function MobileNavIcon({open}: Readonly<{ open: boolean }>) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function MobileNavigation({isLoggedin}: Readonly<{ isLoggedin: boolean }>) {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({open}) => <MobileNavIcon open={open}/>}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50"/>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/pricing">Pricing</MobileNavLink>
            <MobileNavLink href="/blog">Blog</MobileNavLink>
            <MobileNavLink href="/tutorials">Tutorials</MobileNavLink>
            <MobileNavLink href="/faq">FAQ</MobileNavLink>
            <MobileNavLink href="/contact">Contact</MobileNavLink>
            <MobileNavLink href="/support">Support</MobileNavLink>
            <hr className="m-2 border-slate-300/40"/>
            {isLoggedin ? <MobileNavLink href="/dashboard">Dashboard</MobileNavLink> : null}
            {isLoggedin ? <MobileNavLink onClick={logout}>Logout</MobileNavLink> : null}
            {!isLoggedin ? <MobileNavLink href="/login">Login</MobileNavLink> : null}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header({isLoggedin}: Readonly<{ isLoggedin: boolean }>) {
  return (
    <header className="py-10 z-40">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link href="/" aria-label="Home">
              <Logo className="h-10 w-auto"/>
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink href="/pricing">Pricing</NavLink>
              <NavLink href="/blog">Blog</NavLink>
              <NavLink href="/tutorials">Tutorials</NavLink>
              <NavLink href="/faq">FAQ</NavLink>
              <NavLink href="/contact">Contact</NavLink>
              <NavLink href="/support">Support</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-4 md:gap-x-6">
            <div className="hidden md:block" suppressHydrationWarning={true}>
              {isLoggedin ? (
                <>
                  <NavLink href="/dashboard">Dashboard</NavLink>
                  <NavLink onClick={logout}>
                    <ArrowRightEndOnRectangleIcon className="h-5 w-5 inline-block -mt-1 mr-1.5"/>
                  </NavLink>
                </>
              ) : null}
              {!isLoggedin ? <NavLink href="/login">Login</NavLink> : null}
            </div>
            {!isLoggedin ? (<Button href="/register" color="blue">
              <span>
                Get started <span className="hidden lg:inline">today</span>
              </span>
            </Button>) : null}
            <div className="-mr-1 md:hidden">
              <MobileNavigation isLoggedin={isLoggedin}/>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
